import Vue from 'vue';
import { VueLocalization } from '@openticket/vue-localization';
import type { VueI18n } from '@openticket/vue-localization';
import VueNotifications from '@openticket/vue-notifications';

Vue.use(VueLocalization);
Vue.use(VueNotifications, {
    duration: 5000,
    singleNotification: true,
});

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
export const i18n: VueI18n = Vue.prototype.$localization.getI18n();
