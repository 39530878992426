<template>
    <iframe
        id="ot-shop-widget__popup"
        ref="iframe"
        class="ot-shop-widget__popup ot-card"
        :class="{ opened, seatsActive, transitioning }"
        :src="$widget.shopURL"
        title="shop widget"
        frameborder="0"
        @load="onLoad()"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import type { IFrameResizedData, IFrameComponent } from 'iframe-resizer';
import { iframeResizer } from 'iframe-resizer';

@Component
export default class Popup extends Vue {

    @Ref('iframe')
        iframe!: IFrameComponent;

    seatsActive = false;

    iframeHeight = 200;

    opened = false;

    transitioning = false;

    transitionStoppedListener: null | ((this: Popup) => void) = null;

    transitionFallbackTimer = 0;

    async mounted(): Promise<void> {
        iframeResizer({
            checkOrigin: false,
            minHeight: 200,
            resizedCallback: this.iframeResized,
            scrolling: true,
            sizeHeight: false,
        }, this.iframe);

        window.addEventListener('resize', this.setIframeHeight);

        this.transitionStoppedListener = () => {
            this.transitioning = false;
            window.clearTimeout(this.transitionFallbackTimer);
        };

        this.iframe.addEventListener('transitionend', this.transitionStoppedListener);
        this.iframe.addEventListener('transitioncancel', this.transitionStoppedListener);

        await this.$widget.initialized;

        this.$widget.client.onSeats((data: { state: 'created' | 'mounted' | 'destroy' | 'validation' }) => {
            switch (data.state) {
                case 'created':
                case 'mounted':
                case 'validation':
                    this.seatsActive = true;
                    break;
                default:
                    this.seatsActive = false;
                    break;
            }

            this.setIframeHeight();
        });
    }

    beforeDestroy(): void {
        if (this.transitionStoppedListener) {
            this.iframe.removeEventListener('transitionend', this.transitionStoppedListener);
            this.iframe.removeEventListener('transitioncancel', this.transitionStoppedListener);
        }

        this.transitionStoppedListener = null;

        this.transitioning = false;
        window.clearTimeout(this.transitionFallbackTimer);
    }

    iframeResized(data: IFrameResizedData) {
        this.iframeHeight = data.height;

        this.setIframeHeight();
    }

    setIframeHeight() {
        this.iframe.style.height = (window.innerWidth < 625 || this.seatsActive) ? '100%' : `${this.iframeHeight}px`;
    }

    async onLoad(): Promise<void> {
        if (!this.iframe.contentWindow) {
            throw new Error('No access to iframe\'s content window');
        }

        await this.$widget.initClient(this.iframe.contentWindow);
    }

    @Watch('$widget.opened', { immediate: true })
    onWidgetOpenedState(newState: boolean | null): void {
        if ((!newState) !== (!this.opened)) {
            window.clearTimeout(this.transitionFallbackTimer);

            this.transitioning = true;

            // Set a fallback here, there is a chance the transition won't start
            // and thus never finish/cancel, which would keep the popup inside the viewport.
            // The timeout is intentionally quite a bit larger than the transition time.
            this.transitionFallbackTimer = window.setTimeout(() => {
                this.transitioning = false;
            }, 2000);
        }

        this.opened = !!newState;
    }

}
</script>

<style lang="scss" scoped>
iframe {
    position: fixed;
    bottom: -100%;
    right: 16px;
    border-radius: var(--ot-card-border-radius);
    padding: 0;
    transition: transform 0.3s ease, opacity 0.3s ease, max-width .3s ease, height .3s ease;
    opacity: 0;
    transform: translateY(40px);
    pointer-events: none;
    max-height: calc(100% - calc(80px + var(--ot-shop-widget-margin-bottom, 16px) + var(--ot-shop-widget-margin-top, 16px)));
    width: calc(100% - 36px);
    max-width: 450px;
    background: var(--ot-shop-background);
    z-index: var(--ot-shop-widget-popup-z-index, var(--ot-shop-widget-z-index, 1050));

    &.opened {
        opacity: 1;
        transform: none;
        pointer-events: auto;
    }

    &.opened, &.transitioning {
        bottom: calc(80px + var(--ot-shop-widget-margin-bottom, 16px));

        @media screen and (max-width: 624px) {
            bottom: 0;
        }
    }

    &.seatsActive {
        max-width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 624px) {
        right: 0;
        width: 100%;
        max-width: none;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        position: fixed;
    }
}
</style>
