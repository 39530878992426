<template>
    <div
        class="ot-shop-widget__button position"
        :class="{ 'opened': $widget.opened }"
    >
        <p
            v-if="connected"
            class="ot-shop-widget__button__label label ot-text-small-strong"
        >
            {{ label }}
        </p>

        <div
            ref="buttonWrapper"
            class="ot-shop-widget__button__wrapper button-wrapper"
            :aria-expanded="$widget.opened"
            aria-controls="ot-shop-widget__popup"
            aria-label="Open shop widget"
            role="button"
            tabindex="0"
            @click="$emit('click')"
            @keydown.space="$emit('click')"
            @keydown.enter="$emit('click')"
        >
            <div
                ref="button"
                class="ot-card button"
                :class="{ animateShake }"
                ontouchstart=""
            >
                <div
                    v-show="!connected"
                    class="ot-spinner"
                />
                <i
                    v-show="connected && $widget.opened"
                    class="oti oti-drop-down"
                />
                <p
                    v-show="connected && !$widget.opened && ticketCount"
                    class="ot-text-intro-strong"
                >
                    {{ ticketCount }}
                </p>
                <img
                    v-if="iconURL"
                    v-show="connected && !$widget.opened && !ticketCount"
                    class="button__logo"
                    :src="iconURL"
                    alt="Logo"
                >
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Ref } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Style from '../utils/style';

interface PartialCartData {
    tickets: { [guid: string]: { count: number; }; };
    products: { [guid: string]: { count: number; }; };

    checkout_details: { total_price: number; };
}

@Component
export default class Button extends Vue {

    @Ref('buttonWrapper')
        buttonWrapper!: HTMLDivElement;

    @Ref('button')
        button!: HTMLDivElement;

    connected = false;

    ticketCount = 0;

    price: number | null = null;

    animateShake = false;

    iconURL: string | null = null;

    themeListenerId!: number;

    created(): void {
        this.iconURL = this.getIconURL();

        this.themeListenerId = Style.addThemeListener(() => {
            this.iconURL = this.getIconURL();
        });
    }

    destroy(): void {
        Style.removeThemeListener(this.themeListenerId);
    }

    getIconURL(): string {
        let url!: string;
        if (Style.getAppliedTheme() === 'dark') {
            url = import.meta.env.VITE_WHITELABEL_ICON_URL || 'https://cdn.openticket.tech/whitelabels/{{whitelabel}}/graphics/icon.svg';
        } else {
            url = import.meta.env.VITE_WHITELABEL_ICON_DARK_URL || 'https://cdn.openticket.tech/whitelabels/{{whitelabel}}/graphics/icon_dark.svg';
        }

        const { hostname } = new URL(this.$widget.shopURL);
        const whitelabel = hostname.split('.').splice(-2).join('.');

        return url.replace('{{whitelabel}}', whitelabel);
    }

    async mounted() {
        await this.$widget.initialized;

        this.connected = this.$widget.client.isConnected;

        await this.$localization.setLocale(this.$widget.client.localization.locale);

        this.$widget.client.onLocale((data: { locale: string }) => {
            this.$localization.setLocale(data.locale).catch((err: unknown) => {
                console.error(err);
            });
        });

        this.$widget.client.onCartData((data: PartialCartData) => {
            this.animateShake = true;
            this.button.addEventListener('animationend', () => {
                this.animateShake = false;
            });

            if (data) {
                this.setTicketCount(data.tickets);

                const hasItems = Object.keys(data.tickets).length > 0 || Object.keys(data.products).length > 0;

                this.price = hasItems ? data.checkout_details.total_price : null;
            }
        });
    }

    setTicketCount(tickets: PartialCartData['tickets']) {
        if (!Object.keys(tickets).length) {
            this.ticketCount = 0;
            return;
        }

        let count = 0;

        Object.values(tickets).forEach((ticket) => {
            count += ticket.count;
        });

        this.ticketCount = count;
    }

    get label(): string {
        if (this.price !== null) {
            return this.$t('widget.button_cart_label', {
                total: this.$l.currency(
                    this.price,
                    this.$widget.client.localization.currency,
                ),
            }) as string;
        }

        return this.$t('widget.button_cta_label') as string;
    }

}
</script>

<style lang="scss" scoped>
.ot-shop-widget__button {
    bottom: var(--ot-shop-widget-margin-bottom, 16px);
    right: 16px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    z-index: var(--ot-shop-widget-button-z-index, var(--ot-shop-widget-z-index, 1050));

    &.opened {
        @media screen and (max-width: 624px) {
            display: none;
        }
    }

    &__label {
        border-radius: var(--ot-button-border-radius);
        background: var(--ot-color-core-accent-secondary);
        color: var(--ot-color-core-foreground-primary);
        box-shadow: var(--ot-shadow-md);
        margin: 0 16px 0 0;
        padding: 10px;
    }

    &__wrapper {
        border-radius: var(--ot-card-border-radius);
        cursor: pointer;

        .button {
            color: var(--ot-color-core-white);
            border-radius: var(--ot-card-border-radius);
            background: var(--ot-color-core-brand);
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.15s ease;
            cursor: pointer;

            .button__logo {
                height: 2rem;
            }

            .ot-spinner::after {
                border-right-color: var(--ot-color-core-white);
                border-bottom-color: var(--ot-color-core-white);
            }

            &:active {
                transform: scale(0.9);
            }

            &.animateShake {
                animation: shake 0.35s linear;
            }
        }
    }
}

@keyframes shake {
    0% {
        transform: none;
    }
    25% {
        transform: translate(-0px, 0) rotate(-10deg) scale(1.05);
    }
    50% {
        transform: translate(0, 0) rotate(0deg) scale(1.1);
    }
    75% {
        transform: translate(0px, 0) rotate(10deg) scale(1.05);
    }
    100% {
        transform: none;
    }
}
</style>
