<template>
    <div class="ot-shop-container ot-shop-widget">
        <Popup ref="popup" />
        <Button
            ref="button"
            @click="$widget.toggle()"
        />
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import type { NotificationConfig } from '@openticket/vue-notifications';
import Popup from './components/Popup.vue';
import Button from './components/Button.vue';
import type { StyleTheme } from './utils/style';
import Style from './utils/style';

@Component({
    components: {
        Button,
        Popup,
    },
})
export default class App extends Vue {

    @Prop() shopId!: string;

    @Ref('popup')
        popup!: Popup;

    @Ref('button')
        button!: Button;

    public async created(): Promise<void> {
        Vue.observable(this.$widget);

        await Promise.all([
            this.initLocalization(),
            this.initShopSettings(this.shopId),
        ]);

        window.addEventListener('click', (e) => {
            const target = e.target as HTMLElement;

            if (
                !target.className.includes('ot-notification')
                && (
                    !this.button
                    || !this.button.buttonWrapper
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    || !this.button.buttonWrapper.contains(target)
                ) && (
                    !this.popup
                    || !this.popup.iframe
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    || !this.popup.iframe.contains(target))
            ) {
                this.$widget.close();
            }
        });

        await this.$widget.initialized;

        this.$widget.client.onNotification((data: NotificationConfig) => {
            this.$notifications.show(data);
        });
    }

    private async initShopSettings(shopId: string): Promise<void> {
        if (!this.$settings) {
            return;
        }

        await this.$settings.init({
            baseUrl: import.meta.env.VITE_SHOP_SETTINGS_API_URL || 'https://custom.shop.openticket.tech',
            httpRead: axios.create(),
            shopId,
        });

        // Set custom style props
        if (this.$settings.static && this.$settings.static.style) {
            Style.publicSetStyle(this.$settings.static.style);
        }

        if (this.$settings.static && this.$settings.static.theme) {
            Style.setTheme(this.$settings.static.theme as StyleTheme);
        }
    }

    private async initLocalization(): Promise<void> {
        await this.$localization.init();
    }

}
</script>

<style lang="scss">
:root {
    --ot-shop-widget-z-index: 1050;
    --ot-shop-widget-popup-z-index: var(--ot-shop-widget-z-index, 1050);
    --ot-shop-widget-button-z-index: var(--ot-shop-widget-z-index, 1050);

    --ot-shop-widget-margin-top: 16px;
    --ot-shop-widget-margin-bottom: 16px;

    --ot-shop-widget-notification-container-z-index: calc(var(--ot-shop-widget-z-index, 1050) + 49);
}

.ot-document {
    --ot-card-border-radius: 16px;
}

.ot-shop-widget {
    z-index: var(--ot-shop-widget-z-index, 1050);
}

body .ot-notification-container {
    z-index: var(--ot-shop-widget-notification-container-z-index, 1000);
}
</style>
